import { useContext } from "react";
import { Container, Table } from "reactstrap";
import { FormContext } from "../context/FormContext";
import ReportTable from "./ReportTable";
import ReportTableSummary from "./ReportTableSummary";
import { parseReportResults } from "../utils/parseReportResults";
import { groupBy } from "../utils/groupBy";
import { getAssetIdsOfTeesAndEndpoints } from "../utils/cableFunctions";

import "./ResultReport.css";

export const getSteadyVoltagePassResult = (asset) => {
  return asset && asset.flickerPassResult ? "Pass" : "Refer";
};

export const getStartingVoltagePassResult = (asset) => {
  return asset && asset.flickerStartingPassResult ? "Pass" : "Refer";
};

const ResultReport = ({
  reportId,
  networkTransformers,
  networkPointOfConnections,
  networkGroupedConnections,
  networkCables,
  networkMotors,
  networkWelders,
}) => {
  const { formState } = useContext(FormContext);
  const { reference, billOfMaterials, clientSettings } = formState;
  const { AssessmentResultsLimitedEnabled } = clientSettings.Features;

  const { REACT_APP_THEME } = process.env;

  const getFeeders = (reportId) => {
    let nearNode = null;
    let farNode = null;
    let feederAssets = [];

    let filteredNetworkCables = networkCables[reportId];
    if (AssessmentResultsLimitedEnabled) {
      const teesAndEndpoints = getAssetIdsOfTeesAndEndpoints(filteredNetworkCables);
      filteredNetworkCables = filteredNetworkCables.filter((cable) =>
        teesAndEndpoints.includes(cable.endAssetId),
      );
    }

    filteredNetworkCables.forEach((cable) => {
      Object.values({
        transformers: networkTransformers[reportId],
        networkPointOfConnections: networkPointOfConnections[reportId],
        groupedConnections: networkGroupedConnections[reportId],
        welders: networkWelders[reportId],
        motors: networkMotors[reportId],
      }).forEach((assets) => {
        if (Array.isArray(assets)) {
          assets.forEach((asset) => {
            if (asset.nodeNumber) {
              if (asset.nodeNumber === cable.nearNodeNumber) {
                nearNode = asset;
              }
              if (asset.nodeNumber === cable.farNodeNumber) {
                farNode = asset;
              }
            }
          });
        }
      });
      feederAssets.push({
        feederNumber: cable.feederNumber,
        cable: cable,
        nearNode: nearNode,
        farNode: farNode,
        cableResults: parseReportResults(cable, clientSettings),
        farNodeResults: parseReportResults(farNode, clientSettings),
      });
    });
    return groupBy(feederAssets, "feederNumber");
  };

  const getFlicker = (reportId) => {
    let flickerAssets = [];
    networkMotors[reportId] &&
      networkMotors[reportId].forEach((motor) => {
        flickerAssets.push({
          asset: motor,
          results: parseReportResults(motor, clientSettings),
        });
      });
    networkWelders[reportId] &&
      networkWelders[reportId].forEach((welder) => {
        flickerAssets.push({
          asset: welder,
          results: parseReportResults(welder, clientSettings),
        });
      });
    return flickerAssets;
  };

  const getBomCables = (reportId) => {
    let bomCables = [];
    networkCables[reportId].forEach((cable) => {
      var found = bomCables.some((bomCable) =>
        cable.cableType === "auto"
          ? bomCable.cableType === cable.cableTypeAssigned
          : bomCable.cableType === cable.cableType,
      );
      if (!found) bomCables.push(JSON.parse(JSON.stringify(cable)));
      else {
        var objIndex = bomCables.findIndex((bomCable) =>
          cable.cableType === "auto"
            ? bomCable.cableType === cable.cableTypeAssigned
            : bomCable.cableType === cable.cableType,
        );

        var length = (
          (parseFloat(bomCables[objIndex].overrideLength)
            ? parseFloat(bomCables[objIndex].overrideLength)
            : parseFloat(bomCables[objIndex].length)) +
          (parseFloat(cable.overrideLength)
            ? parseFloat(cable.overrideLength)
            : parseFloat(cable.length))
        ).toFixed(2);

        if (parseFloat(bomCables[objIndex].overrideLength)) {
          bomCables[objIndex].overrideLength = length;
        } else {
          bomCables[objIndex].length = length;
        }
      }
    });
    return bomCables;
  };

  const getTransformers = (reportId) => {
    let transformerAssets = [];
    networkTransformers[reportId] &&
      networkTransformers[reportId].forEach((transformer) => {
        transformerAssets.push({
          asset: transformer,
          results: parseReportResults(transformer, clientSettings),
        });
      });
    return transformerAssets;
  };

  const getTransformer = (reportId) => {
    const firstTransformer = networkTransformers[reportId][0];

    if (!firstTransformer) {
      return undefined;
    }

    return {
      phaseAngle: firstTransformer["phaseAngle"],
      phasesAvailable: firstTransformer["phasesAvailable"],
    };
  };

  const getSeason = (resultName) => {
    let resultCategories = [];
    clientSettings.filterResults.forEach((category) => {
      category.info.forEach((res) => {
        if (res.season) {
          resultCategories.push(res);
        }
      });
    });
    const result = resultCategories.find((res) => res.name === resultName);
    if (result) return result.season;
    return "";
  };

  const getConstraint = (category, name, option = "") => {
    const result = clientSettings.filterResults
      .find((f) => f.category === category)
      .info.find((i) => i.name === name);

    return option ? result.options.find((i) => i.name === option).constraint : result.constraint;
  };

  const showAlert = (asset, faultName, results = "results") => {
    const fault = asset[results].find((r) => r.category === faultName);
    return fault && fault.constraint ? "alert alert-danger" : "";
  };

  const getReportLabel = (tab) => {
    const v = networkTransformers[tab];
    const i = Object.keys(networkTransformers).indexOf(tab);
    return v[0].annotation || v[0].substationId || `Transformer ${i + 1}`;
  };

  return (
    <>
      <Container className="report pagebreak">
        <h3 className="print-report mb-4" style={{ paddingLeft: "0.3rem" }}>
          {getReportLabel(reportId)}
        </h3>
        <ReportTableSummary
          header="Summary of Connections"
          network={{
            transformers: networkTransformers[reportId],
            cables: networkCables[reportId],
            groupedConnections: networkGroupedConnections[reportId],
            welders: networkWelders[reportId],
            motors: networkMotors[reportId],
          }}
          theme={REACT_APP_THEME}
        />
        <h5 style={{ paddingLeft: ".3rem" }}>Transformers</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Tx Number</th>
              <th>Type</th>
              <th>No Phases</th>
              <th>Auto</th>
              <th>Rating (kVA)</th>
              <th>Design Voltage (V)</th>
              {!AssessmentResultsLimitedEnabled ? (
                <>
                  <th>HV Fault Level</th>
                  <th>HV X/R</th>
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {Object.values(networkTransformers[reportId]).map((transformer) => (
              <tr key={transformer.id}>
                <td>{transformer.nodeNumber}</td>
                <td>{transformer.mounting}</td>
                <td>{transformer.numberOfPhases}</td>
                <td>{transformer.autoSelect && transformer.autoSelect.toString()}</td>
                <td>
                  {transformer.nameplateRating === "auto"
                    ? transformer.nameplateRatingAssigned
                    : transformer.nameplateRating}
                </td>
                <td>{transformer.designVoltage}</td>
                {!AssessmentResultsLimitedEnabled ? (
                  <>
                    <td>{transformer.hvFaultLevel}</td>
                    <td>{transformer.xrRatio}</td>
                  </>
                ) : null}
                <td>{transformer.annotation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Cables</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Branch (near-far)</th>
              <th>Cable Group</th>
              <th>No Phases</th>
              <th>Auto</th>
              <th>Cable Type</th>
              <th>Length (m)</th>
              <th>Ducted</th>
              <th>Distributed Connections</th>
            </tr>
          </thead>
          <tbody>
            {networkCables[reportId].map((cable) => (
              <tr key={cable.id}>
                <td>
                  {cable.nearNodeNumber} - {cable.farNodeNumber}
                </td>
                <td>{cable.cableGroup}</td>
                <td>{cable.numberOfPhases}</td>
                <td>{cable.autoSelect.toString()}</td>
                {cable.cableType === "auto" ? (
                  <td>{cable.cableTypeAssigned}</td>
                ) : (
                  <td>{cable.cableType}</td>
                )}
                <td>
                  {parseFloat(cable.overrideLength)
                    ? cable.overrideLength
                    : parseFloat(cable.length).toFixed(2)}
                </td>
                <td>{cable.ducting.toString()}</td>
                <td>{cable.groupedConnectionPoints.length > 0 ? "true" : "false"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {networkGroupedConnections[reportId] && (
          <ReportTable
            header="Nodal Connections"
            obj={networkGroupedConnections[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkCables[reportId] && (
          <ReportTable
            header="Distributed Connections"
            obj={networkCables[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkTransformers[reportId] && (
          <ReportTable
            header="Transformer Connections"
            obj={networkTransformers[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkMotors[reportId] && networkMotors[reportId].length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Motors</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node No</th>
                  <th>No Phases</th>
                  <th>Rating</th>
                  <th>Rating Unit</th>
                  <th>Starter</th>
                  <th>No Starts</th>
                  <th>Starts Unit</th>
                  <th>Starting PF</th>
                  <th>Start Current Override</th>
                  <th>Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {networkMotors[reportId].map((motor) => (
                  <tr key={motor.id}>
                    <td>{motor.nodeNumber}</td>
                    <td>{motor.numberOfPhases}</td>
                    <td>{motor.rating}</td>
                    <td>{motor.ratingUnit}</td>
                    <td>{motor.starter}</td>
                    <td>{motor.numberOfStarts}</td>
                    <td>{motor.numberOfStartsUnit}</td>
                    <td>{motor.startingPowerFactor}</td>
                    <td>{motor.startingCurrentOverride}</td>
                    <td>{motor.efficiency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {networkWelders[reportId] && networkWelders[reportId].length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Welders</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node No</th>
                  <th>No Phases</th>
                  <th>Rating kVA</th>
                  <th>Max Welder Current</th>
                  <th>Voc</th>
                  <th>No Bursts</th>
                  <th>Bursts Unit</th>
                  <th>POWC</th>
                  <th>Starting PF</th>
                  <th>Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {networkWelders[reportId].map((welder) => (
                  <tr key={welder.id}>
                    <td>{welder.nodeNumber}</td>
                    <td>{welder.numberOfPhases}</td>
                    <td>{welder.rating}</td>
                    <td>?</td>
                    <td>{welder.voc}</td>
                    <td>{welder.numberOfBursts}</td>
                    <td>{welder.numberOfBurstsUnit}</td>
                    <td>{welder.pointOfWaveControl.toString()}</td>
                    <td>{welder.powerFactor}</td>
                    <td>{welder.efficiency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Container>
      {clientSettings.Features.CostingEnabled ? (
        <Container className="report pagebreak">
          <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
            Bill Of Materials
          </h2>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Status</th>
                <th>Work Category 1</th>
                <th>SWE</th>
                <th>SWE Description</th>
                <th className=" text-md-right">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {billOfMaterials && billOfMaterials?.items ? (
                billOfMaterials.items.map((bom, index) => (
                  <tr key={bom.swe}>
                    <td>{bom.status}</td>
                    <td>{bom.parentCategory}</td>
                    <td>{bom.swe}</td>
                    <td width="60%">{bom.sweDescription}</td>
                    <td className="text-md-right">{bom.quantity}</td>
                  </tr>
                ))
              ) : billOfMaterials ? (
                <tr key={"bom-NA"}>
                  <td colSpan={7}>N/A</td>
                </tr>
              ) : (
                <tr key={"bom-fail"}>
                  <td colSpan={7}>Error - Unable to produce Bill of Materials</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      ) : (
        <Container className="report pagebreak">
          <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
            Bill Of Materials
          </h2>
          <h5 style={{ paddingLeft: ".3rem" }}>Transformer</h5>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Type</th>
                <th>Phases</th>
                <th>Rating (kVA)</th>
              </tr>
            </thead>
            <tbody>
              {networkTransformers[reportId].map((transformer) => (
                <tr key={transformer.id}>
                  <td>{transformer.mounting === "pmt" ? "Pole Mounted" : "Ground Mounted"}</td>
                  <td>{transformer.numberOfPhases}</td>
                  <td>
                    {transformer.nameplateRating === "auto"
                      ? transformer.nameplateRatingAssigned
                      : transformer.nameplateRating}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h5 style={{ paddingLeft: ".3rem" }}>Cables</h5>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Type</th>
                <th>Phases</th>
                <th>Size</th>
                <th>Length (m)</th>
              </tr>
            </thead>
            <tbody>
              {getBomCables(reportId).map((cable) => (
                <tr key={cable.id}>
                  <td>{cable.cableGroup}</td>
                  <td>{cable.numberOfPhases}</td>
                  {cable.cableType === "auto" ? (
                    <td>{cable.cableTypeAssigned}</td>
                  ) : (
                    <td>{cable.cableType}</td>
                  )}
                  <td>
                    {parseFloat(cable.overrideLength)
                      ? cable.overrideLength
                      : parseFloat(cable.length).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
      <Container className="report pagebreak">
        <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
          Results
        </h2>
        {Object.values(getFeeders(reportId)).map((item) => (
          <div key={item[0].feederNumber}>
            <h5 style={{ paddingLeft: ".3rem" }}>
              {`Feeder ${item[0].feederNumber}`}
              <small>
                {" "}
                - Chosen Fuse Size:{" "}
                <span className={item[0].cable.hasFuseMaxCurrentWarning ? "alert-danger" : ""}>
                  {item[0].cable.feederFuseSize}A
                </span>
              </small>
            </h5>
            <Table className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}>
              <thead className="superthead">
                <tr className="mb-4">
                  <th colSpan="2" className="th-separator">
                    Nodes
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 2 : 4} className="th-separator">
                    Branch
                  </th>
                  {!AssessmentResultsLimitedEnabled && (
                    <th colSpan="6" className="th-separator">
                      Far Node
                    </th>
                  )}
                </tr>
              </thead>
              <thead>
                <tr className="mb-4">
                  <th className="th-separator">Near</th>
                  <th>Far</th>
                  <th className="th-separator">Max A</th>
                  <th>% utilisation</th>
                  {!AssessmentResultsLimitedEnabled ? (
                    <>
                      <th>Period</th>
                      <th>Season</th>
                    </>
                  ) : null}
                  {!AssessmentResultsLimitedEnabled && (
                    <>
                      <th className="th-separator">Volt Drop</th>
                      <th>Period</th>
                      <th>Season</th>
                      <th>Volt Rise</th>
                      <th>Period</th>
                      <th>Season</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {item.map((x, i) => (
                  <tr key={i}>
                    <td>{x.nearNode && x.nearNode.nodeNumber}</td>
                    <td>{x.farNode && x.farNode.nodeNumber}</td>
                    <td className={showAlert(x, "maximumUtilisationPercent", "cableResults")}>
                      {x.cable[`${getSeason("Current")}maximumCurrentA`]}
                    </td>
                    <td className={showAlert(x, "maximumUtilisationPercent", "cableResults")}>
                      {x.cable[`${getSeason("Utilisation (%)")}maximumUtilisationPercent`]}
                    </td>
                    {!AssessmentResultsLimitedEnabled ? (
                      <>
                        <td>{x.cable[`${getSeason("Current")}maximumCurrentAPeriod`]}</td>
                        <td>{getSeason("Current")}</td>
                      </>
                    ) : null}
                    {!AssessmentResultsLimitedEnabled && (
                      <>
                        <td className={showAlert(x, "maxVoltDropPercent", "farNodeResults")}>
                          {x.farNode &&
                            x.farNode[`${getSeason("Max Volt Drop (%)")}maxVoltDropPercent`]}
                        </td>
                        <td>
                          {x.farNode &&
                            x.farNode[`${getSeason("Max Volt Drop (%)")}maxVoltDropPercentPeriod`]}
                        </td>
                        <td>{getSeason("Max Volt Drop (%)")}</td>
                        <td className={showAlert(x, "maxVoltRiseTotal", "farNodeResults")}>
                          {x.farNode &&
                            x.farNode[`${getSeason("Max Volt Rise Total (%)")}maxVoltRiseTotal`]}
                        </td>
                      </>
                    )}
                    {!AssessmentResultsLimitedEnabled ? (
                      <>
                        <td>
                          {x.farNode &&
                            x.farNode[
                              `${getSeason("Max Volt Rise Total (%)")}maxVoltRisePercentPeriod`
                            ]}
                        </td>
                        <td>{getSeason("Max Volt Rise Total (%)")}</td>
                      </>
                    ) : null}
                    {!AssessmentResultsLimitedEnabled ? (
                      <>
                        <td className={showAlert(x, "maxVoltRiseGeneration", "farNodeResults")}>
                          {x.farNode &&
                            x.farNode[
                              `${getSeason("Max Volt Rise Generation (%)")}maxVoltRiseGeneration`
                            ]}
                        </td>
                        <td>
                          {x.farNode &&
                            x.farNode[
                              `${getSeason("Max Volt Rise Generation (%)")}maxVoltRisePercentPeriod`
                            ]}
                        </td>
                        <td>{getSeason("Max Volt Rise Generation (%)")}</td>
                        <td className={showAlert(x, "maxVoltRiseUnbalance", "farNodeResults")}>
                          {x.farNode &&
                            x.farNode[
                              `${getSeason("Max Volt Rise Unbalance (%)")}maxVoltRiseUnbalance`
                            ]}
                        </td>
                        <td>
                          {x.farNode &&
                            x.farNode[
                              `${getSeason("Max Volt Rise Unbalance (%)")}maxVoltRisePercentPeriod`
                            ]}
                        </td>
                        <td>{getSeason("Max Volt Rise Unbalance (%)")}</td>
                      </>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead className="superthead">
                <tr className="mb-4">
                  <th colSpan="2" className="th-separator">
                    Nodes
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 1 : 2} className="th-separator">
                    Source Impedance (mΩ)
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 1 : 3} className="th-separator">
                    Fault Level (kA)
                  </th>
                  {AssessmentResultsLimitedEnabled && (
                    <th colSpan="2" className="th-separator">
                      Far Node
                    </th>
                  )}
                  {!AssessmentResultsLimitedEnabled ? (
                    <th colSpan="5" className="th-separator">
                      Flicker
                    </th>
                  ) : null}
                </tr>
              </thead>
              <thead>
                <tr className="mb-4">
                  <th className="th-separator">Near</th>
                  <th>Far</th>
                  <th className="th-separator">Loop</th>
                  {!AssessmentResultsLimitedEnabled ? <th>Phase</th> : null}
                  <th className="th-separator">Phase-Earth</th>
                  {AssessmentResultsLimitedEnabled && (
                    <>
                      <th className="th-separator">Volt Drop</th>
                      <th>Volt Rise</th>
                    </>
                  )}
                  {!AssessmentResultsLimitedEnabled ? (
                    <>
                      <th>Phase-Phase</th>
                      <th>3-Phase</th>
                      <th className="th-separator">Steady State Voltage %</th>
                      <th>Pass/Refer</th>
                      <th>Total Voltage During Starting %</th>
                      <th>Pass/Refer</th>
                      <th>P28 Limit</th>
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {item.map((y, i) => (
                  <tr key={i}>
                    <td>{y.nearNode && y.nearNode.nodeNumber}</td>
                    <td>{y.farNode && y.farNode.nodeNumber}</td>
                    <td className={showAlert(y, "impedanceSourceLoopOperating", "farNodeResults")}>
                      {y.farNode && y.farNode.impedanceSourceLoopOperating}
                    </td>
                    {!AssessmentResultsLimitedEnabled ? (
                      <td>{y.farNode && y.farNode.impedanceSourcePhaseOperating}</td>
                    ) : null}
                    <td
                      className={showAlert(y, "singlePhaseToEarthFaultCurrent", "farNodeResults")}
                    >
                      {y.farNode && y.farNode.singlePhaseToEarthFaultCurrent}
                    </td>
                    {AssessmentResultsLimitedEnabled && (
                      <>
                        <td className={showAlert(y, "maxVoltDropPercent", "farNodeResults")}>
                          {y.farNode &&
                            y.farNode[`${getSeason("Max Volt Drop (%)")}maxVoltDropPercent`]}
                        </td>
                        <td className={showAlert(y, "maxVoltRiseTotal", "farNodeResults")}>
                          {y.farNode &&
                            y.farNode[`${getSeason("Max Volt Rise Total (%)")}maxVoltRiseTotal`]}
                        </td>
                      </>
                    )}
                    {!AssessmentResultsLimitedEnabled ? (
                      <>
                        <td className={showAlert(y, "phaseToPhaseFaultCurrent", "farNodeResults")}>
                          {y.farNode && y.farNode.phaseToPhaseFaultCurrent}
                        </td>
                        <td className={showAlert(y, "threePhaseFaultCurrent", "farNodeResults")}>
                          {y.farNode && y.farNode.threePhaseFaultCurrent}
                        </td>
                        <td className={showAlert(y, "flickerDeltaVoltage", "farNodeResults")}>
                          {y.farNode && y.farNode.flickerDeltaVoltage}
                        </td>
                        <td>{getSteadyVoltagePassResult(y.farNode)}</td>
                        <td
                          className={showAlert(y, "flickerStartingDeltaVoltage", "farNodeResults")}
                        >
                          {y.farNode && y.farNode.flickerStartingDeltaVoltage}
                        </td>
                        <td>{getStartingVoltagePassResult(y.farNode)}</td>
                        <td>{y.farNode && y.farNode.flickerP28Limit}</td>
                      </>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))}
        {Object.values(getFlicker(reportId)).length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Flicker</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node Number</th>
                  <th>Steady State Voltage %</th>
                  <th>Pass/Refer</th>
                  <th>Total Voltage During Starting %</th>
                  <th>Pass/Refer</th>
                  <th>P28 Limit</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(getFlicker(reportId)).map((item) => (
                  <tr key={item.asset.id}>
                    <td>{item.asset.nodeNumber}</td>
                    <td className={showAlert(item, "flickerDeltaVoltage")}>
                      {item.asset.flickerDeltaVoltage}
                    </td>
                    <td>{getSteadyVoltagePassResult(item.asset)}</td>
                    <td className={showAlert(item, "flickerStartingDeltaVoltage")}>
                      {item.asset.flickerStartingDeltaVoltage}
                    </td>
                    <td>{getStartingVoltagePassResult(item.asset)}</td>
                    <td>{item.asset.flickerP28Limit}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        <h5 style={{ paddingLeft: ".3rem" }}>Transformer</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Tx Number</th>
              <th>Rating (kVA)</th>
              <th>Max Load kVA</th>
              <th>Max Utilisation</th>
              <th>Period of Max Load</th>
              <th>Max Volt Drop</th>
              <th>Max Volt Rise Total</th>
              <th>Max Volt Rise Generation</th>
              <th>Max Volt Rise Unbalance</th>
            </tr>
          </thead>
          {Object.values(getTransformers(reportId)).map((item) => (
            <tbody key={item.asset.id}>
              <tr>
                <td>{item.asset.nodeNumber}</td>
                <td className={showAlert(item, "nameplateRatingAssigned")}>
                  {item.asset[`${getSeason("Transformer Rating (kVA)")}nameplateRatingAssigned`]}
                </td>
                <td className={showAlert(item, "maximumDemandKVA")}>
                  {item.asset[`${getSeason("Demand (kVA)")}maximumDemandKVA`]}
                </td>
                <td className={showAlert(item, "maximumUtilisationPercent")}>
                  {item.asset[`${getSeason("Utilisation (%)")}maximumUtilisationPercent`]}
                </td>
                <td className={showAlert(item, "maximumDemandKVAPeriod")}>
                  {item.asset[`${getSeason("Demand (kVA)")}maximumDemandKVAPeriod`]}
                </td>
                <td className={showAlert(item, "maxVoltDropPercent")}>
                  {item.asset[`${getSeason("Max Volt Drop (%)")}maxVoltDropPercent`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseTotal")}>
                  {item.asset[`${getSeason("Max Volt Rise Total (%)")}maxVoltRiseTotal`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseGeneration")}>
                  {item.asset[`${getSeason("Max Volt Rise Generation (%)")}maxVoltRiseGeneration`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseUnbalance")}>
                  {item.asset[`${getSeason("Max Volt Rise Unbalance (%)")}maxVoltRiseUnbalance`]}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Container>
      <Container className="report pagebreak">
        <h5 style={{ paddingLeft: ".3rem" }}>System Parameters</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <tbody>
            <tr>
              <th>System Voltage</th>
              <td style={{ paddingTop: ".3rem" }}>{reference.constraints.systemVoltage}</td>
              <td style={{ paddingTop: ".3rem" }}>V</td>
            </tr>
            <tr>
              <th>Fault Voltage</th>
              <td>250</td>
              <td>V</td>
            </tr>
            <tr>
              <th>System Phase Angle</th>
              <td>{getTransformer(reportId)?.phaseAngle}</td>
              <td>degrees</td>
            </tr>
            <tr>
              <th>Number of Available Phases</th>
              <td>{getTransformer(reportId)?.phasesAvailable}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3" className="pt-4 border-bottom-0">
                Constraint Thresholds
              </td>
            </tr>
            <tr>
              <th>Maximum Mains Volt Drop</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Drop (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Service Volt Drop</th>
              <td>{reference.constraints.maxVoltDropServicePercent}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Total</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Rise Total (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Generation</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Rise Generation (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Unbalance</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Rise Unbalance (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Operating Resistance</th>
              <td>{getConstraint("Impedance", "Resistance (mΩ)", "Loop (operating)")}</td>
              <td>mΩ</td>
            </tr>
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Auto Cable Groups</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <thead>
            <tr className="mb-4">
              <th>Name</th>
              <th>Cable Types</th>
            </tr>
          </thead>
          <tbody>
            {reference.cables.autoSelectGroups.map((group, i) => (
              <tr key={i}>
                <td>{group.name}</td>
                <td>{group.cables.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Auto Transformer Groups</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <thead>
            <tr className="mb-4">
              <th>Name</th>
              <th>Phases</th>
              <th>Tx Size</th>
            </tr>
          </thead>
          <tbody>
            {reference.transformers.autoSelectGroups.map((group, i) => (
              <tr key={i}>
                <td>{group.name}</td>
                <td>{group.phase}</td>
                <td>{group.ratings.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default ResultReport;
